<template>
  <div class="p-10">
    <div class="userinfo anglebox buble-bg">
      <div class="user flex js-sp align-center buble-line">
        <div class="flex js-st align-center" @click="jump('/person')">
          <i class="iconfont quote-key-text mg-rt-md icon-type-04-copy"></i>
          <!-- <img src="@/assets/images/head.png" alt /> -->
          <p class="name buble-t1 text-12 text-left">{{ nickname }}</p>
        </div>
        <i class="el-icon-arrow-right text-14 buble-t1"></i>
      </div>
      <div class="center-model flex js-sp buble-line">
        <ul class="text-left">
          <li class="text-14 buble-t3 mb-8 h_18 block">
            {{ $t("header.wallet") }}
          </li>
          <li
            class="text-12 buble-t1 mb-8 text-hover h_18 break-all"
            @click="jump(item.path)"
            v-for="item in state.person"
            :key="item.name"
          >
            {{ item.name }}
          </li>
        </ul>
        <ul class="text-left max-content" v-if="isBibiShow">
          <li class="text-14 buble-t3 mb-8 h_18 block">
            {{ $t("header.commission") }}
          </li>
          <li
            class="text-12 buble-t1 mb-8 text-hover h_18 break-all"
            v-for="item in state.person2"
            :key="item.name"
            @click="jump(item.path)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="logout text-12" @click="logout()">
        {{ $t("header.signOutOfAccount") }}
      </div>
    </div>
    <Messagebox
      :text="$t('formTip.cashTip')"
      :type="state.safetyType"
      :dialog="state.dialogSafetyTip"
      @onconfirm="confirm"
      @viewSafeStatus="viewSafeStatus"
      @oncancer="onCancel"
    />
    <VerifiedBox
      :text="$t('formTip.certificationTip')"
      :dialog="state.dialogVerfiedTip"
      :type="state.type"
      @onconfirm="onVerfiedConfirm"
      @viewSafeStatus="onVerfiedConfirm"
      @oncancer="onVerfiedCancel"
    />
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Messagebox from "@/components/messageBox";
import VerifiedBox from "@/components/verifiedBox";
export default {
  name: "Personbuble",
  components: { Messagebox, VerifiedBox },
  props: {
    nickname: String,
  },
  setup() {
    const { t } = useI18n();
    const state = reactive({
      safetyType: 0,
      type: 0,
      person: [
        { name: t("lever.assets"), path: "/person/myassets" },
        { name: t("assets.recharge"), path: "/person/recharge" },
        { name: t("assets.withdrawMoney"), path: "/person/cash" },
        { name: t("assets.assetRecord"), path: "/person/AssetsRecord" },
      ],
      person2: [
        {
          name: t("global.currentCommission"),
          path: "/person/ComminManage?active=1",
        },
        {
          name: t("global.historyCommission"),
          path: "/person/ComminManage?active=2",
        },
      ],
      dialogSafetyTip: false,
      dialogVerfiedTip: false,
    });
    // function
    const $router = useRouter();
    const $store = useStore();
    const onVerfiedConfirm = () => {
      state.dialogVerfiedTip = false;
      $router.push("/person/Authentication");
    };
    const onVerfiedCancel = () => {
      state.dialogVerfiedTip = false;
    };
    //判断币币交易是否开启
    const isBibiShow = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = false;
      list.forEach((ele) => {
        if (ele.key === "currency_transaction_status") {
          flag = ele.value;
        }
      });
      return flag;
    });
    //是否需要实名
    const isVerified = computed(() => {
      let list = $store.state.user.systemInfo;
      let flag = false;
      list.forEach((ele) => {
        if (ele.key === "verify_switch") {
          if (
            ele.value[5] &&
            $store.state.user.securityInfo.user_auth_level !== 2
          ) {
            if ($store.state.user.securityInfo.user_auth_level !== 4) {
              state.type = 1;
            }
            flag = true;
          }
        }
      });
      return flag;
    });
    const jump = (path) => {
      if (path === "/person/cash") {
        if (isVerified.value) {
          state.dialogVerfiedTip = true;
          return false;
        }
        if (
          securityInfo.value.user_safety_certificate_status &&
          securityInfo.value.user_safety_certificate_type !== 2
        ) {
          if (securityInfo.value.user_safety_certificate_type !== 4) {
            state.safetyType = 1;
          }
          state.dialogSafetyTip = true;
          return;
        }
      }
      $router.push(path);
    };
    const securityInfo = computed(() => {
      return $store.state.user.securityInfo;
    });
    const confirm = () => {
      state.dialogSafetyTip = false;
      $router.push("/person/Advanced");
    };
    const viewSafeStatus = () => {
      state.dialogSafetyTip = false;
      $router.push("/person/Advanced");
    };
    const onCancel = () => {
      state.dialogSafetyTip = false;
    };
    const logout = () => {
      $store.dispatch("user/logout").then(() => {
        $router.push("/login");
      });
    };
    return {
      state,
      logout,
      jump,
      securityInfo,
      confirm,
      onCancel,
      onVerfiedConfirm,
      onVerfiedCancel,
      isVerified,
      viewSafeStatus,
      isBibiShow,
    };
  },
};
</script>

<style lang="less" scoped>
// 个人中心弹窗
.userinfo {
  min-width: 170px;
  position: relative;
  // border-radius: 8px;
  padding: 0 10px;
}
.user {
  height: 44px;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}
.iconfont {
  font-size: 24px;
}
.is-rtl {
  .el-icon-arrow-right {
    transform: scaleX(-1);
    // direction: rtl;
  }
}
.name {
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-inline-start: 6px;
}
.center-model {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.mb-8 {
  margin-bottom: 8px;
}
.h_18 {
  min-height: 18px;
  line-height: 18px;
}
.logout {
  height: 37px;
  line-height: 37px;
  color: #cf2f43;
}
.break-all {
  word-break: break-all;
}
.block {
  display: block;
}
ul li {
  display: inline-block;
  width: 100%;
}
.max-content {
  width: max-content;
}
</style>
