import axios from "axios";
import store from "../store";
import { getToken } from "../utils/auth";
import router from "../router";
import VueI18n from "../language";
import { ElMessage, ElMessageBox } from "element-plus";
import websocketTool from "@/components/websocket/websocketTool";
// import VueI18n from "../language";
let baseURL = window.VUE_APP_API;
// if (process.env.NODE_ENV !== "development") {
// }

//发送退出登录的消息
const offLine = () => {
  let user = localStorage.getItem("user_info");
  if (user !== null) {
    user = JSON.parse(user);
  }
  localStorage.removeItem("online");
  if (websocketTool?.ws && websocketTool?.ws?.readyState == 1) {
    websocketTool?.ws.send(
      JSON.stringify({
        socket_type: `OFFLINE_${user?.id}`,
        subscribed: 1,
      })
    );
  }
};

// create an axios instance
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
  // `validateStatus` 定义对于给定的HTTP 响应状态码是 resolve 或 reject  promise 。如果 `validateStatus` 返回 `true` (或者设置为 `null` 或 `undefined`)，promise 将被 resolve; 否则，promise 将被 rejecte
  validateStatus: function(status) {
    return status >= 200 && status < 500; // default
  },
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      //   // let each request carry token
      //   // ['access-token'] is a custom headers key
      config.headers["access-token"] = getToken();
    }
    if (store.getters.local) {
      config.headers["language"] = store.getters.local;
    }
    return config;
    // do something before request is
  },
  (error) => {
    // do something with request error
    console.log("requestError" + error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 10006) {
      store.dispatch("user/removeToken");
      offLine();
      // 关键代码，判断当前页是否有el-message标签，如果没有则执行弹窗操作
      if (
        document.getElementsByClassName("el-message-box").length === 0 ||
        document.getElementsByClassName("el-message-box__wrapper")[0].style
          .display == "none"
      ) {
        ElMessageBox.confirm(
          VueI18n.global.t("formTip.loginExpired"),
          VueI18n.global.t("tips.tips"),
          {
            confirmButtonText: VueI18n.global.t("confirm"),
            cancelButtonText: VueI18n.global.t("cancel"),
            type: "warning",
            customClass: "msg-box",
          }
        )
          .then(() => {
            store
              .dispatch("user/resetToken")
              .then(() => {
                location.reload();
              })
              .catch(() => {
                router.push("/login");
              });
          })
          .catch(() => {
            store.dispatch("user/logout").then(() => {
              return false;
            });
          });
      }

      return false;
    } else if (res.code === 10007) {
      store.dispatch("user/logout").then(() => {});
      router.push("/login");
      return;
    } else if (res.code === 8888) {
      router.push("/maintenance");
      return;
    }

    if (response.status === 200) {
      return res;
    } else if (
      res.code === 63017 ||
      res.code === 63013 ||
      res.code === 63014 ||
      res.code === 63019 ||
      res.code === 63020 ||
      res.code === 94006 ||
      res.code === 94005 ||
      res.code === 62013 ||
      res.code === 81007 ||
      res.code === 68002
    ) {
      return Promise.reject(res);
    } else if (response.status === 404 || response.status === 400) {
      ElMessage.error(res.message);
      return Promise.reject(res);
    } else {
      ElMessage.error(res.message);
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    // Toast.fail(error.message);
    ElMessage.error(error.message);
    return Promise.reject(error);
  }
);

export default service;
