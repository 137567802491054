<template>
  <div>
    <el-dialog
      :title="title ? title : $t('formTip.tip')"
      @close="cancer"
      v-model="state.dialogVisible"
      width="400px"
    >
      <div class="text-left p_30">
        <div class="color_1e mb-10 text-16">{{ text }}</div>
        <div class="text-14 quotet3" v-if="type === 1">
          {{ $t("formTip.waitSafetyStatus") }}
        </div>
      </div>
      <div class="flex js-sp buble-t1 text-center m-btn" v-if="showbtn">
        <div class="letter-btn flex-grow-1 nobtn point" @click="cancer">
          {{ $t("formTip.cancel") }}
        </div>
        <div
          class="letter-btn flex-grow-1 acbtn point ml-25"
          @click="confirm"
          v-if="type !== 1"
        >
          {{ type === 3 ? $t("confirm") : $t("formTip.certification") }}
        </div>
        <div
          class="letter-btn flex-grow-1 acbtn point ml-25"
          @click="toViewSafeStatus"
          v-if="type === 1"
        >
          {{ $t("formTip.viewStatus") }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
export default {
  props: {
    dialog: Boolean,
    text: String,
    title: String,
    type: Number,
    showbtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["onconfirm", "oncancer", "viewSafeStatus"],
  setup(props, { emit }) {
    const state = reactive({
      dialogVisible: props.dialog,
    });
    watch(props, (val) => {
      state.dialogVisible = val.dialog;
    });
    const cancer = () => {
      state.dialogVisible = false;
      emit("oncancer");
    };
    const confirm = () => {
      state.dialogVisible = false;
      emit("onconfirm");
    };
    const toViewSafeStatus = () => {
      state.dialogVisible = false;
      emit("viewSafeStatus");
    };
    return {
      state,
      confirm,
      cancer,
      toViewSafeStatus,
    };
  },
};
</script>

<style scoped lang="less">
.letter-tip {
  width: 100%;
  padding: 14px 20px;
  box-sizing: border-box;
}
.letter-btn {
  width: 50%;
  height: 46px;
  line-height: 46px;
  border-radius: 4px;
}
.mb-24 {
  margin-bottom: 24px;
}
.color-1e {
  color: #121212;
}
.color-98 {
  color: #989898;
}
.p_30 {
  padding: 14px 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
.m-btn {
  margin: 0px 20px 16px 20px;
}
.ml-25 {
  margin-inline-start: 25px;
}
</style>
