import {
  loginByPhone,
  loginByEmail,
  refreshToken,
  userinfoDetail,
  getConfig,
} from "@/api/user";
import { getProtocolType } from "@/api/article";
import {
  getToken,
  setToken,
  getTokenTime,
  setTokenTime,
  removeToken,
  removeTokenTime,
  setRefreshToken,
  removeRefreshToken,
  getRefreshToken,
} from "@/utils/auth";
import websocketTool from "@/components/websocket/websocketTool";
const tokenv = getToken();
const tokent = getTokenTime();
let userInfo = localStorage.getItem("user_info");
if (userInfo !== "null") {
  userInfo = JSON.parse(userInfo);
}
let online_states = localStorage.getItem("online");
if (online_states !== "null") {
  online_states = JSON.parse(JSON.stringify(online_states));
}
const state = {
  tab: null,
  accountReg: null,
  timer: null,
  token: tokenv,
  tokenTime: tokent,
  userInfo: userInfo,
  securityInfo: null, // 安全状态信息
  local: "en",
  localary: [],
  changeLocal: 0,
  localDetail: "", //用来帮助，资讯，公告详情里面判断切换语言
  theme: "dark",
  levertradingtab: 2, //合约交易对展示 默认usdt
  stocktradingtab: 2, //股票投资交易对展示，默认美股
  secondtradingtab: 2, //秒合约交易对展示 默认usdt
  dealtradingtab: 2, //币币交易对展示 默认usdt
  safety_verification_key: null, // 安全验证key
  coinComfirm: true, // true开启币币交易二次弹窗
  levelComfirm: true, // true开启合约交易二次弹出
  certificateType: null,
  online: online_states, //在线状态
  systemInfo: null,
  protocol: null,
  virtual_wallet: null,
  fastExchange: true, //闪兑显示状态
  activeParam: {
    activation_time: 0, //激活时间
    id: 0, //内容id
    showStatus: false, //是否弹出过
  }, //首页弹窗参数
  levelPage: "level", //页面头部合约交易或秒合约交易显示
  nftPage: "NFT", //页面头部NFT或元宇宙交易显示
  contractActive: null, //个人中心合约管理的侧边栏二级菜单
  stockActive: null, //个人中心股票管理的侧边栏二级菜单
  klStudies: [], //k线指标
  foreignKlStudies: [], //外汇k线指标
  stockKlStudies: [], //股票k线指标
};
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_CONTRACTACTION: (state, contractActive) => {
    state.contractActive = contractActive;
  },
  SET_STOCKACTION: (state, stockActive) => {
    state.stockActive = stockActive;
  },
  SET_LEVER_PAGE: (state, levelPage) => {
    state.levelPage = levelPage;
  },
  SET_NFT_PAGE: (state, nftPage) => {
    state.nftPage = nftPage;
  },
  SET_TIMER: (state, timer) => {
    state.timer = timer;
  },
  SET_TAB: (state, tab) => {
    state.tab = tab;
  },
  SET_ACCOUNTREG: (state, accountReg) => {
    state.accountReg = accountReg;
  },
  SET_TOKEN_TIME: (state, tokenTime) => {
    state.tokenTime = tokenTime;
  },
  SET_LOCAL: (state, local) => {
    state.local = local;
  },
  SET_LOCALARY: (state, localary) => {
    state.localary = localary;
  },
  SET_LOCAL_DETAIL: (state, localDetail) => {
    state.localDetail = localDetail;
  },
  SET_CHANGELOCAL: (state, changeLocal) => {
    state.changeLocal = changeLocal;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_THEME: (state, theme) => {
    console.log(theme);
    state.theme = theme;
  },
  SET_SECURITY_INFO: (state, securityInfo) => {
    state.securityInfo = securityInfo;
  },
  SET_SAFETY_KEY: (state, safety_verification_key) => {
    state.safety_verification_key = safety_verification_key;
  },
  SET_COIN_COMFIRM: (state, coinComfirm) => {
    state.coinComfirm = coinComfirm;
  },
  SET_LEVER_COMFIRM: (state, levelComfirm) => {
    state.levelComfirm = levelComfirm;
  },
  SET_CERTIFICATE_TYPE: (state, certificateType) => {
    state.certificateType = certificateType;
  },
  SET_TRADING_TAB: (state, param) => {
    if (param.type === "lever") {
      state.levertradingtab = param.active;
    } else if (param.type === "stock") {
      state.stocktradingtab = param.active;
    } else {
      state.dealtradingtab = param.active;
    }
  },
  SET_TRADING_TAB_SEC: (state, param) => {
    state.secondtradingtab = param.active;
  },
  SET_ONLINE: (state, online) => {
    state.online = online;
  },
  SET_SYSTEMINFO: (state, systemInfo) => {
    state.systemInfo = systemInfo;
  },
  SET_PROTOCOL: (state, protocol) => {
    state.protocol = protocol;
  },
  SET_FASTEXCHANGE: (state, fastExchange) => {
    state.fastExchange = fastExchange;
  },
  SET_ACTIVEPARAM: (state, activeParam) => {
    state.activeParam = activeParam;
  },
  SET_KLSTUDIES: (state, klStudies) => {
    state.klStudies = klStudies;
  },
  SET_STOCKKLSTUDIES: (state, stockKlStudies) => {
    state.stockKlStudies = stockKlStudies;
  },
  SET_FOREIGN_KLSTUDIES: (state, foreignKlStudies) => {
    state.foreignKlStudies = foreignKlStudies;
  },
  SET_VIRTUAL_WALLET: (state, virtual_wallet) => {
    state.virtual_wallet = virtual_wallet;
  },
};

const offLine = () => {
  let user = localStorage.getItem("user_info");
  if (user !== null) {
    user = JSON.parse(user);
  }
  localStorage.removeItem("online");
  if (websocketTool?.ws && websocketTool?.ws?.readyState == 1) {
    if (user) {
      websocketTool?.ws.send(
        JSON.stringify({
          socket_type: `OFFLINE_${user.id}`,
          subscribed: 1,
        })
      );
    }
  }
};

const actions = {
  // user login
  login({ commit }, params) {
    console.log(params);
    const requestFun = params.loginType === 1 ? loginByPhone : loginByEmail;
    return new Promise((resolve, reject) => {
      requestFun(params)
        .then((response) => {
          if (response.code === 200) {
            console.log(response);
            const data = response.data;
            const timestamp = Date.parse(new Date()) / 1000;
            console.log(timestamp, data.access_token_valid_time);
            commit("SET_TOKEN_TIME", data.token.access_token_valid_time - 50);
            console.log("state", state, data.token.access_token);
            commit("SET_TOKEN", data.token.access_token);
            setToken(data.token.access_token);
            setTokenTime(data.token.access_token_valid_time - 50);
            setRefreshToken(data.token.refresh_token);
            let user_info = data.user_base;
            if (params.loginType === 1) {
              localStorage.setItem("phone", user_info.user_phone);
            } else {
              localStorage.setItem("email", user_info.user_email);
            }
            commit("SET_USER_INFO", user_info);
            commit("SET_ONLINE", "online");
            localStorage.setItem("online", "online");
            localStorage.setItem("user_info", JSON.stringify(user_info));
            this.dispatch("user/changeSecurity");
            resolve();
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      offLine();
      commit("SET_TOKEN", "");
      commit("SET_TOKEN_TIME", "");
      removeToken();
      removeTokenTime();
      removeRefreshToken();
      commit("SET_USER_INFO", null);
      commit("SET_SECURITY_INFO", null);
      commit("SET_VIRTUAL_WALLET", null);
      localStorage.removeItem("user_info");
      resolve();
    });
  },
  removeToken({ commit }) {
    commit("SET_TOKEN", "");
  },
  // remove token
  resetToken({ commit }) {
    const refreshtoken = getRefreshToken();
    return new Promise((resolve, reject) => {
      if (refreshtoken) {
        refreshToken(refreshtoken)
          .then((response) => {
            if (response?.code === 200) {
              const data = response.data;
              // const timestamp = Date.parse(new Date()) / 1000
              let time = data.token.access_token_valid_time - 50;
              commit("SET_TOKEN", data.token.access_token);
              commit("SET_TOKEN_TIME", time);
              setToken(data.token.access_token);
              setTokenTime(time);
              setRefreshToken(data.token.refresh_token);
              let user_info = data.user_base;
              commit("SET_ONLINE", "online");
              localStorage.setItem("online", "online");
              commit("SET_USER_INFO", user_info);
              localStorage.setItem("user_info", JSON.stringify(user_info));
              this.dispatch("user/changeSecurity");
              resolve();
            } else {
              commit("SET_TOKEN", "");
              commit("SET_TOKEN_TIME", "");
              removeToken();
              removeTokenTime();
              removeRefreshToken();
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        commit("SET_TOKEN", "");
        commit("SET_TOKEN_TIME", "");
        removeToken();
        removeTokenTime();
        removeRefreshToken();
        reject();
      }
    });
  },
  // 修改模拟账号状态
  changeVirtualWallet({ commit }, params) {
    return new Promise((resolve) => {
      console.log(params);
      commit("SET_VIRTUAL_WALLET", params);
      resolve();
    });
  },

  // 修改语言
  changeLocal({ commit }, params) {
    return new Promise((resolve) => {
      console.log(params);
      commit("SET_LOCAL", params);
      commit("SET_CHANGELOCAL", 1);
      resolve();
    });
  },
  // 修改主题
  changeTheme({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_THEME", params);
      resolve();
    });
  },
  //获取协议配置信息
  getProtocolConfig({ commit }) {
    return new Promise((resolve, reject) => {
      getProtocolType()
        .then((response) => {
          if (response.code === 200) {
            const data = response.data;
            commit("SET_PROTOCOL", data);
            resolve();
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //获取系统配置信息
  changeSystemConfig({ commit }) {
    return new Promise((resolve, reject) => {
      getConfig()
        .then((response) => {
          if (response.code === 200) {
            const data = response.data;
            commit("SET_SYSTEMINFO", data);
            resolve();
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取安全中心状态信息
  changeSecurity({ commit }) {
    return new Promise((resolve, reject) => {
      userinfoDetail()
        .then((response) => {
          if (response.code === 200) {
            const data = response.data;
            commit("SET_SECURITY_INFO", data);
            resolve();
          } else {
            reject(response.message);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 修改安全验证key
  changeSafetyKey({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_SAFETY_KEY", params);
      resolve();
    });
  },
  // 修改币币交易二次确认弹窗
  changeCoinComfirm({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_COIN_COMFIRM", params);
      resolve();
    });
  },
  // 修改合约交易二次确认弹窗
  changeLevelComfirm({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_LEVER_COMFIRM", params);
      resolve();
    });
  },
  changeCertificateType({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_CERTIFICATE_TYPE", params);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
